const settings = JSON.parse(localStorage.getItem('settings'));

const rifaview = () => {
  const view = localStorage.getItem('rifaview')
  if (view) return view
  else return 'table'
}
const getItem = (item) => {
  const value = localStorage.getItem(item)
  return value === 'true' ? true : false
}
const initialState = { data: { rifa: { view: rifaview(), detail: getItem('show_detail'), unpaid: getItem('show_unpaid') }, query: '' } }


export const utils = {
  namespaced: true,
  state: initialState,
  actions: {
    section({ commit }, section) {
      commit('section', section);
    },
    changetheme({ commit }, mode) {
      commit('mode', mode);
    },
    rifaView({ commit }, view) {
      commit('rifaview', view);
    },
    rifaDetail({ commit }, show) {
      commit('showdetail', show);
    },
    rifaUnpaid({ commit }, unpaid) {
      commit('showunpaid', unpaid);
    },
    query({ commit }, query) {
      commit('saveQuery', query);
    },
  },
  mutations: {
    section(state, section) {
      state.data.section = section;
    },
    theme(state, mode) {
      state.data.theme = mode;
    },
    rifaview(state, view) {
      localStorage.setItem('rifaview', view);
      state.data.rifa.view = view;
    },
    showdetail(state, show) {
      localStorage.setItem('show_detail', show);
      state.data.rifa.detail = show;
    },
    showunpaid(state, unpaid) {
      localStorage.setItem('show_unpaid', unpaid);
      state.data.rifa.unpaid = unpaid;
    },
    saveQuery(state, query) {
      state.data.query = query;
    },
  }
};
