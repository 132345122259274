
import AuthService from '../services/auth.service';
let arkey = JSON.parse(localStorage.getItem('arkey'));
let data = () => { if (localStorage.getItem('arkey')) {
  let userdata =  JSON.parse(localStorage.getItem('arkey')).access.split(".")[1];
  userdata = JSON.parse(atob( userdata ));
  let data = JSON.parse(userdata.sub);
  data.id = userdata.user_id;
  return data
  } else { return null}};

const initialState = arkey
  ? { status: { loggedIn: true }, user: { arkey, data: data() }}
  : { status: { loggedIn: false }, user: { arkey: null, data: null }}

export const auth = {
  namespaced: true,
  state: initialState,
  actions: {
    login({ commit }, user) {
      return AuthService.login(user).then(
        response => {
          commit('loginSuccess', response.data);
          return Promise.resolve(response.data);
        },
        error => {
          commit('loginFailure');
          return Promise.reject(error);
        }
      );
    },
    logout({ commit }) {
      AuthService.logout();
      commit('logout');
    },
    register({ commit }, user) {
      return AuthService.register(user).then(
        response => {
          commit('registerSuccess');
          return Promise.resolve(response.data);
        },
        error => {
          commit('registerFailure');
          return Promise.reject(error);
        }
      );
    },
    refresh({ commit }) {
      return AuthService.refresh()
      .then( arkey => {
        commit('refreshToken', arkey);
        return Promise.resolve(arkey);
      },error => {
        commit('logout');
        return Promise.reject(error);
      })
    },
  },
  mutations: {
    loginSuccess(state, arkey) {
      state.status.loggedIn = true;
      state.user = { arkey, data: data() };
    },
    loginFailure(state) {
      state.status.loggedIn = false;
      state.user.arkey = null;
      state.user.data = null;
    },
    logout(state) {
      state.status.loggedIn = false;
      state.user.arkey = null;
      state.user.data = null;
      window.location.href = "/app";
    },
    registerSuccess(state) {
      state.status.loggedIn = false;
    },
    registerFailure(state) {
      state.status.loggedIn = false;
    },
    refreshToken(state, arkey) {
      state.status.loggedIn = true;
      state.user = { arkey: arkey, data: data() };
    }
  }
};
