import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import {
  faAt,
  faUser,
  faKey,
  faCircleExclamation,
  faAngleDown,
  faList,
  faCalendarDays,
  faHeart,
  faGear,
  faPersonRunning,
  faPhone,
  faPlus,
  faMagnifyingGlass,
  faRightToBracket,
  faHome,
  faCheck,
  faXmark,
  faGrip,
  faMinus,
  faCircleInfo,
  faGlobe,
  faNotEqual,
} from "@fortawesome/free-solid-svg-icons";
import { faAddressCard } from '@fortawesome/free-regular-svg-icons'

library.add(faAt, faUser, faKey, faCircleExclamation,
  faAngleDown, faList, faCalendarDays, faHeart, faGear,
  faPersonRunning, faPhone, faPlus, faMagnifyingGlass,
  faRightToBracket, faAddressCard, faHome, faCheck, faXmark,
  faGrip, faMinus,faCircleInfo, faGlobe,
  faNotEqual,
  );

export { FontAwesomeIcon };