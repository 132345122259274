
import RifaService from '../services/rifa.service';
// let arkey = JSON.parse(localStorage.getItem('arkey'));
// let data = () => { if (localStorage.getItem('arkey')) {
//   let userdata =  JSON.parse(localStorage.getItem('arkey')).access.split(".")[1];
//   userdata = JSON.parse(atob( userdata ));
//   let data = JSON.parse(userdata.sub);
//   data.id = userdata.user_id;
//   return data
//   } else { return null}};

const initialState = { data: { list: null, detail: null, get: null, item: null, }, message: null  }
//   : { status: { loggedIn: false }, user: { arkey: null, data: null }}

export const rifa = {
  namespaced: true,
  state: initialState,
  actions: {
    list({ commit }) {
      return RifaService.list().then(
        response => {
          commit('listSuccess', response.data);
          return Promise.resolve(response.data);
        },
        error => {
          commit('getRifaFailure');
          return Promise.reject(error);
        }
      );
    },
    listDetail({ commit }) {
      return RifaService.listDetail().then(
        response => {
          commit('listDetailSuccess', response.data);
          return Promise.resolve(response.data);
        },
        error => {
          commit('getRifaFailure');
          return Promise.reject(error);
        }
      );
    },
    get({ commit }, id) {
      return RifaService.get(id).then(
        response => {
          commit('getSuccess', response.data);
          return Promise.resolve(response.data);
        },
        error => {
          commit('getRifaFailure');
          return Promise.reject(error);
        }
      );
    },
    updateTicket({ commit }, data) {
      return RifaService.updateTicket(data).then(
        response => {
          commit('updateTicketSuccess', response.data);
          return Promise.resolve(response.data);
        },
        error => {
          commit('updateTicketFailure');
          return Promise.reject(error);
        }
      );
    },
    create({ commit }, recipe) {
      return RifaService.create(recipe).then(
        response => {
          commit('createSuccess', response.data);
          return Promise.resolve(response.data);
        },
        error => {
          commit('createFailure');
          return Promise.reject(error);
        }
      );
    },
  },
  mutations: {
    listSuccess(state, res) {
      state.data.list = res.data;
      state.message = res.message;
    },
    listDetailSuccess(state, res) {
      state.data.detail = res.data;
      state.message = res.data;
    },
    getSuccess(state, res) {
      state.data.get = res.data;
      let arr = [];
      let items =  res.data.gamedata.data.tickets
      for (var k in items) {
        arr.push(items[k]);
      }
      state.data.get.tickets = arr.sort((a, b) => (a.id > b.id ? 1 : b.id > a.id ? -1 : 0));
      state.message = res.message;
    },
    getRifaFailure(state) {
      state.data.list = null;
      state.data.detail = null
      state.data.get = null
    },
    updateTicketSuccess(state, res) {
      state.message = res.message;
    },
    updateTicketFailure(state) {
      state.message = null
    },
    createSuccess(state, data) {
      state.data.res = data;
    },
    createFailure(state) {
      state.data.res = null;
    },

  }
};
