import axios from 'axios';

const API_URL = process.env.VUE_APP_API_ROOT;

class AuthService {
  login(user) {
    return axios.post(API_URL + 'auth/login/', {
      username: user.username,
      password: user.password
    }).then(response => {
      if (response.data.access && response.data.refresh) {
        localStorage.setItem('arkey', JSON.stringify(response.data));
      }
      return response.data;
    });
  }
  logout() {
    localStorage.removeItem('arkey');
  }
  register(user) {
    return axios.post(API_URL + 'accounts/', {
      country: 'CO',
      fname: user.fname,
      lname: user.lname,
      username: user.phone,
      email: user.email,
      password: user.password,
      password2: user.password2,
    },
    );
  }
  refresh() {
    let auth = JSON.parse(localStorage.getItem('arkey'))
    return axios.post(API_URL + 'auth/refresh/', {
      refresh: auth.refresh
    }).then(response => {
      if (response.data.access && response.data.refresh) {
        localStorage.setItem('arkey', JSON.stringify(response.data));
      }
      return response.data
    })
  }
}
export default new AuthService();
