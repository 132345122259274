import { createRouter, createWebHistory } from 'vue-router'

const routes = [
  {
    path: '/',
    name: 'home',
    component: () => import('@/components/home/Home.vue'),
    meta: {
      requiresAuth: false,
      app: false,
    }
  },
  {
    path: '/app',
    name: 'app',
    component: () => import('@/components/app/App.vue'),
    meta: {
      requiresAuth: false,
    }
  },
  {
    path: '/app/sorteos/aa',
    name: 'game-detail',
    component: () => import('@/components/app/App.vue'),
    meta: {
      requiresAuth: false,
    }
  },
  {
    path: '/app/sorteos/crear',
    name: 'game-new',
    component: () => import('@/components/app/App.vue'),
    meta: {
      requiresAuth: true,
    }
  },
  //  RIFAS
  {
    path: '/app/games/rifas',
    name: 'games-rifas',
    component: () => import('@/components/app/App.vue'),
    meta: {
      requiresAuth: true,
    }
  },
  {
    path: '/app/auth/login',
    name: 'login',
    component: () => import('@/components/auth/Auth.vue'),
    meta: {
      requiresAuth: false,
    }
  },
  {
    path: "/app/auth/register",
    name: "register",
    component: () => import('@/components/auth/Auth.vue'),
    meta: {
      requiresAuth: false,
    }
  },
  {
    path: "/app/auth/logout",
    name: "logout",
    component: () => import('@/components/auth/Auth.vue'),
    meta: {
      requiresAuth: false,
      onlyAuth: true,
    }
  },
  {
    path: '/:pathMatch(.*)*',
    name: 'NothingToDo',
    component: () => import('@/components/404.vue'),
    meta: {
      requiresAuth: false,
      app: false,
    }
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  // scrollBehavior(to, from, savedPosition) {
  // return savedPosition || new Promise((resolve) => {
  // setTimeout(() => resolve({ top: 0 }), 300)
  // })

  // scrollBehavior(to, from, savedPosition) {
  //   if (savedPosition) {
  //     console.log(savedPosition)
  //     return savedPosition
  //   } else {
  //     return { top: 0 }
  //   }
  // }
  scrollBehavior(to, from, savedPosition) {
    if (to.hash) {
      return {
        selector: to.hash
      };
    }
  }
})

router.beforeEach((to, from) => {
  if (to.meta.onlyAuth && to.meta.requiresAuth && !localStorage.getItem('arkey')) {
    return { path: from.fullPath }
  }
  else if (to.meta.requiresAuth && !localStorage.getItem('arkey')) {
    return { name: 'login', query: { redirect: to.fullPath } }
  }
})

export default router
