<!-- @format -->

<template>
  <main class="main">
    <router-view v-slot="{ Component }">
      <!-- <transition enter-active-class="animate__animated animate__fadeInTopLeft" -->
        <!-- leave-active-class="animate__animated animate__fadeOutBottomRight" mode="out-in"> -->
      <transition enter-active-class="animate__animated animate__fadeIn"
      leave-active-class="animate__animated animate__fadeOut" mode="out-in">
      <component :is="Component" />
      </transition>
    </router-view>
  </main>
</template>

<script>
  export default {
    components: {},
    data() {
      return {
        last: "",
      };
    },
    computed: {},
    methods: {},
    // watch:{
    // $route (to, from){
    //     // react to route changes
    // }
    // },
  };
</script>
<style lang="scss">
  :root {
    --g-dark: #212121;
    --g-light: #e8e8e8;
    --g-light-alt: #e0e4cc;
    --g-primary: #fa6900;
    --g-primary-alt: #f38630;
    --g-secondary: #69d2e7;
    --g-secondary-alt: #a7dbd8;
    --g-alt: #ff3d7f;
    --g-grad: linear-gradient(
      -45deg,
      var(--g-alt),
      var(--g-primary),
      var(--g-secondary),
      var(--g-alt)
    );
    --g-grad1: linear-gradient(to right, var(--g-primary), var(--g-alt));
    --g-grad2: linear-gradient(to right, var(--g-secondary), var(--g-light));
    --g-grad3: linear-gradient(to right, var(--g-primary), var(--g-alt));
    --g-grad4: linear-gradient(to right, var(--g-dark), var(--g-light));
  }

  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }

  a {
    text-decoration: none;

    &:visited {
      color: inherit;
    }
    &:active {
      color: var(--g-primary);
      transform: scale(0.95);
    }
  }

  .ncp {
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p {
    margin: 0;
    padding: 0;
  }

  .main,
  #root {
    min-height: 100vh;
    color: var(--g-dark);
    font-size: 1.2rem;
    font-family: "Quicksand", sans-serif;
    letter-spacing: 0.2rem;
    background: var(--g-grad);
    background-size: 500% 500%;
    animation: gradientbg 80s ease infinite;
    // font-family: 'Quicksand', sans-serif;
    // font-family: 'Fredoka One', cursive;
    // font-family: 'Major Mono Display', monospace;
  }

  .loader {
    z-index: -1;
    position: fixed;
    top: 0;
    left: 0;
    min-height: 100vh;
    width: 100%;
    background: var(--g-grad);
    background-size: 300% 300%;
    animation: gradientbg 5s ease infinite;

    &-item {
      font-family: "Fredoka One", cursive;
      font-size: 3rem;
      color: var(--g-light);
    }
  }

  .section {
    min-height: 100vh;
    width: 100%;
  }
  .overlay {
    z-index: 1000;
    height: 100vh;
    width: 100%;
    background: var(--g-dark);
    opacity: .8;
  }
  .flex {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  // Form

  .form-card {
    padding: 2rem 1.2rem;
    text-align: center;
    border-radius: 2rem;
    flex-direction: column;
    gap: 2rem;
  }

  /*Title*/
  .form-title {
    font-family: "Fredoka One", cursive;
    font-size: 2rem;
    font-weight: 500;
    color: var(--g-light);
  }

  .form-container {
    flex-direction: column;
    gap: 1rem;
  }

  /*Inputs*/
  .form-field {
    margin-top: 0.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 1rem;
    background-color: var(--g-light-alt);
    border-radius: 1rem;
    padding: 0.6rem 1rem;
    width: 100%;

    /*Hover & focus*/
    .form-input-field:focus::placeholder {
      opacity: 0;
      transition: opacity 0.3s;
    }

    &:focus-within {
      // outline: 2px solid var(--g-dark);
      background-color: var(--g-light);
    }
  }

  .form-input-icon {
    height: 1.2em;
    width: 1.2rem;
    color: var(--g-primary);
  }

  .form-input-field {
    background: none;
    border: none;
    outline: none;
    width: 100%;
    letter-spacing: 0.2rem;
    font-size: 1.2rem;
    font-family: "Quicksand", sans-serif;
    color: var(--g-dark);
  }

  .form-btn-link {
    color: var(--g-light);
    display: block;
    font-size: 1rem;
    transition: color 0.3s ease-out;

    &:hover {
      color: var(--g-dark);
    }
  }

  .form-field-error {
    height: 1.2em;
    width: 1.2rem;
    color: var(--g-alt);
    opacity: 0.8;
  }

  .header-title {
    font-size: 4rem;
    font-family: "Fredoka One", cursive;
    text-align: center;
  }

  @keyframes gradientbg {
    0% {
      background-position: 0% 50%;
    }
    50% {
      background-position: 100% 50%;
    }
    100% {
      background-position: 0% 50%;
    }
  }

  @keyframes rotate3d {
    0% {
      transform: rotateY(0deg);
    }
    25% {
      color: var(--g-primary);
    }
    50% {
      transform: rotateY(-360deg);
      color: var(--g-alt);
    }
    75% {
      color: var(--g-secondary);
    }
    100% {
      transform: rotateY(0deg);
    }
  }

  @keyframes colortext {
    0% {
      color: inherit;
    }
    25% {
      color: var(--g-primary);
    }
    50% {
      color: var(--g-alt);
    }
    75% {
      color: var(--g-secondary);
    }
    100% {
      color: inherit;
    }
  }

  @media only screen and (max-width: 1200px) {
  }

  @media only screen and (max-width: 992px) {
  }

  @media only screen and (max-width: 768px) {
    html {
      font-size: 14px;
    }
  }

  @media only screen and (max-width: 560px) {
    html {
      font-size: 12px;
    }
  }

  @media only screen and (max-width: 320px) {
  }
</style>
