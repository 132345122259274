import { createApp } from 'vue'
import App from './Root.vue'
import router from './router'
import store from './store';
import 'animate.css';
import { partytownSnippet } from '@builder.io/partytown/integration';
// import { googleSnippets } from './plugins/google';
import { FontAwesomeIcon } from '@/plugins/font-awesome';

createApp(App)
.component('font-awesome-icon', FontAwesomeIcon)
.use(router)
.use(store)
.mount('#root')

const partytownScript = document.createElement('script');
partytownScript.text = partytownSnippet();
document.head.appendChild(partytownScript);

const addStylesheet = (url) => {
    const link = document.createElement('link');
    link.rel = 'stylesheet';
    link.href = url;
    document.head.appendChild(link)
}

const addScript = (item) => {
    const script = document.createElement('script');
    script.type = item.type;
    script.async = item.async;
    script.src = item.url;
    document.head.appendChild(script);
}

addStylesheet('https://fonts.googleapis.com/css2?family=Fredoka+One&family=Quicksand&family=Major+Mono+Display&display=swap"');

// addScript({
//     url: googleSnippets.async,
//     type: 'text/partytown',
//     async: true,
// })

// addScript({
//     url: googleSnippets.script,
//     type: 'text/partytown',
//     async: false,
// })

const refresh = () => {
  this.$store
    .dispatch("auth/refresh")
    .then()
    .catch((e) => {
      console.error(
        "No se pudo comprobar la identidad, por favor, ingresa nuevamente."
      );
      this.$store.dispatch("auth/logout");
    });
}