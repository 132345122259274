import axios from 'axios';
import authHeader from './auth-header';

const API_URL = process.env.VUE_APP_API_ROOT + 'games/';

class RifaService {
  list() {
    return axios.get(API_URL + 'rifas/', { headers: authHeader() });
  }
  listDetail() {
    return axios.get(API_URL + 'rifas/?detail=1', { headers: authHeader() });
  }
  get(id) {
    return axios.get(API_URL + `rifas/${id}/?detail=1`, { headers: authHeader() });
  }
  updateTicket(data) {
    return axios.patch(API_URL + `rifas/${data.id}/`,
      data.req
      , { headers: authHeader() }
    )
  }
  create(recipe) {
    return axios.post(API_URL + 'recipes/', {
      title: recipe.title,
      image: recipe.image,
      ingredients: recipe.ingredients,
      method: recipe.method,
      type: 'desayuno',
      public: recipe.public,
    }, { headers: authHeader() }
    )
  }
}

export default new RifaService();
