
import { createStore } from "vuex";
import { utils } from "./utils.module";
import { auth } from "./auth.module";
import { rifa } from "./rifa.module";

const store = createStore({
  modules: {
    utils,
    auth,
    rifa
  },
});

export default store;